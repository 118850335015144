import React, { useEffect, useState } from 'react';

import Map from '../../components/Map/Map';
import InfoBanner from '../../components/infoBanner/InfoBanner';
import InfoPanel from '../../components/infoPanel/InfoPanel';
import { getSocket, getTracking } from '../../websocket/websocket';
import rideTrackApi from '../../services/rideTrackApi';

import './App.css';
import {
  RIDE_CANCELED,
  RIDE_FINISHED,
  RIDE_NOTAVAILABLE,
  RIDE_STARTED,
} from '../../misc/constants';
// import AvatarIcon from '../../components/avatarIcon/AvatarIcon';

const App = () => {
  const [position, setPosition] = useState(null);
  const [rideStatus, setRideStatus] = useState(null);
  const [socketData, setSocketData] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [trackSocket, setTrackSocket] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [trackId, setTrackId] = useState(null);
  const [showInfoPanel, setShowInfoPanel] = useState(true);

  useEffect(() => {
    const getTrackId = window.location.pathname;

    setTrackId(getTrackId.split('/')[1]);

    return () => {
      if (trackSocket) {
        trackSocket.off('update');
        trackSocket.off('session_update');
        trackSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (trackId) {
      handleApiData();
    }
  }, [trackId]);

  useEffect(() => {
    if (apiData?.is_live) {
      const socket = getSocket();

      setTrackSocket(socket);
      handleSocket(socket);
    }
  }, [apiData]);

  const handleSocket = (socket) => {
    // Uncomment to test in staging
    // eslint-disable-next-line max-len
    // getTracking({ socket, id: 'af08d58c-84ea-11ec-bdee-066d1b03d2c6', callback: handleSocketkData });
    getTracking({ socket, id: trackId, callback: handleSocketkData });
    socket.on('update', handleSocketUpdate);
    socket.on('session_update', handleSocketSessionUpdate);
  };

  const handleApiData = async () => {
    const api = rideTrackApi(trackId);

    try {
      const data = await api.getInfo();

      setApiData(data);

      if (!data?.is_live) {
        if (data?.status === RIDE_FINISHED) {
          setPosition(data?.position?.stop);
          setRideStatus({ status: RIDE_FINISHED });
        } else if (data?.status === RIDE_CANCELED) {
          setRideStatus({ status: RIDE_CANCELED });
        }
      }

      // console.log('ApiData:', data);
    } catch (error) {
      if (error.response.status === 404 || error.response.status === 410) {
        setRideStatus({ status: RIDE_NOTAVAILABLE });
      }
      console.warn(error);
    }
  };

  const handleSocketkData = (data) => {
    // console.log('SocketData:', data);
    setSocketData(data);

    if (data.error) {
      setRideStatus({ status: RIDE_FINISHED });
    } else {
      setRideStatus({ status: RIDE_STARTED });
      setPosition({ lat: data?.lat, lng: data?.lng });
    }
  };

  const handleSocketUpdate = (payload) => {
    // console.log('[SOCKET_update]:', payload);
    setPosition(payload);
  };

  const handleSocketSessionUpdate = (payload) => {
    // console.log('[SOCKET_session_update]:', payload);
    if (payload?.status === RIDE_FINISHED) {
      setRideStatus({ status: RIDE_FINISHED });
      setPosition({ lat: payload?.lat, lng: payload?.lng });
    } else if (payload?.status === RIDE_CANCELED) {
      setRideStatus({ status: RIDE_CANCELED });
    }
  };

  return (
    <div className="App">
      <div className={`App-body ${rideStatus?.status === RIDE_NOTAVAILABLE ? 'App-noride' : null}`}>
        {/* <AvatarIcon avatar={apiData?.user?.avatar_url} /> */}
        {
          rideStatus?.status !== RIDE_NOTAVAILABLE
            ? (
              <Map
                position={position}
                rideStatus={rideStatus}
                apiData={apiData}
                showInfoPanel={showInfoPanel}
              />
            )
            : null
        }
        { rideStatus && (
          <InfoBanner
            rideStatus={rideStatus}
            socketData={socketData}
            apiData={apiData}
          />
        )}
        { showInfoPanel && <InfoPanel onClose={setShowInfoPanel} />}
      </div>
    </div>
  );
};

export default App;
