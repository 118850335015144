import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  isMacOs, isIOS,
} from 'react-device-detect';

import { yegoLogo, closeIcon } from '../../misc/images';
import Button from '../button/Button';
import './InfoPanel.css';

const InfoPanel = (props) => {
  const { t } = useTranslation();

  const linkManagement = () => {
    let link = '';

    if (isIOS || isMacOs) {
      link = 'https://itunes.apple.com/es/app/yugo-mobility/id1181020675?mt=8';
    } else {
      link = 'https://play.google.com/store/apps/details?id=com.getyugo.app';
    }

    return link;
  };

  const onClose = () => {
    props.onClose?.(false);
  };

  return (
    <div className="infopanel-container">
      <div className="infopanel">
        <div
          className="infopanel-close-button-container"
          onClick={onClose}
          role="button"
          aria-hidden="true"
        >
          <img className="infopanel-close-button" src={closeIcon} alt="close" />
        </div>
        <div className="infopanel-logo-container">
          <img className="infopanel-logo" src={yegoLogo} alt="logo" />
        </div>
        <div className="infopanel-text-container">
          <div className="infopanel-title">
            <p>{t('shareride.shared.title')}</p>
          </div>
          <div className="infopanel-description">
            <p>{t('shareride.shared.body')}</p>
          </div>
        </div>
        <div className="button-container">
          <Button link={linkManagement()} />
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
