import axios from 'axios';

const rideTrackApi = (trackId) => {
  const test = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const getInfo = async () => {
    const response = await test.get(`/live/${trackId}`);

    return response.data;
  };

  return { getInfo };
};

export default rideTrackApi;
