/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

import getMinutesBetweenDates from '../../misc/utils';
import {
  RIDE_CANCELED,
  RIDE_FINISHED,
  RIDE_NOTAVAILABLE,
  RIDE_STARTED,
} from '../../misc/constants';
import './InfoBanner.css';

const InfoBanner = (props) => {
  const { t } = useTranslation();

  const userName = props.apiData?.user?.name;
  const startDate = new Date(props.socketData?.lastActionDate);
  const actualDate = new Date();
  const differenceInMinutes = getMinutesBetweenDates(startDate, actualDate);

  const getRideMessage = () => {
    if (props.rideStatus?.status === RIDE_STARTED) {
      return <p>{t('shareride.shared.status.ridestarted', { name: userName, number: differenceInMinutes < 0 ? 0 : differenceInMinutes })}</p>;
    }

    if (props.rideStatus?.status === RIDE_NOTAVAILABLE || props.rideStatus?.status === RIDE_CANCELED) {
      return <p>{t('shareride.shared.status.rideunavailable')}</p>;
    }

    if (props.rideStatus?.status === RIDE_FINISHED) {
      return <p>{t('shareride.shared.status.ridefinished', { name: userName })}</p>;
    }

    return null;
  };

  return (
    <div className="infobanner-container">
      <div className="infobanner">
        {getRideMessage()}
      </div>
    </div>
  );
};

export default InfoBanner;
