import React, { useCallback } from 'react';
import {
  GoogleMap,
  useLoadScript,
} from '@react-google-maps/api';

import SingleMarker from '../singleMarker/SingleMarker';
import {
  MARKER_POSITIONSIZE,
  MARKER_USERSIZE,
  RIDE_CANCELED,
  TYPE_BIKE,
  TYPE_KICK,
  TYPE_SCOOTER,
} from '../../misc/constants';
import {
  availableBikeActive, availableScooterActive, availableKickActive, departIcon, arrivalIcon,
} from '../../misc/images';
import mapStyle from '../../misc/mapStyle';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const Map = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    styles: mapStyle,
  };

  // eslint-disable-next-line no-undef
  const renderMarkerIconUrl = (vehicle) => {
    if (vehicle?.type === TYPE_SCOOTER) {
      return availableScooterActive;
    }

    if (vehicle?.type === TYPE_BIKE) {
      return availableBikeActive;
    }

    if (vehicle?.type === TYPE_KICK) {
      return availableKickActive;
    } return availableScooterActive;
  };

  const centerMapWithPanel = {
    lat: props.position?.lat - 0.003,
    lng: props.position?.lng,
  };

  const renderMapMarkers = useCallback(() => {
    if (props.rideStatus?.status !== RIDE_CANCELED) {
      return (
        <>
          <SingleMarker
            position={props.apiData?.position?.start}
            size={MARKER_POSITIONSIZE}
            icon={departIcon}
          />
          <SingleMarker
            position={props.position}
            size={MARKER_USERSIZE}
            icon={renderMarkerIconUrl()}
          />
          {props.apiData?.position?.stop && (
            <SingleMarker
              position={props.apiData?.position?.stop}
              size={MARKER_POSITIONSIZE}
              icon={arrivalIcon}
            />
          )}
        </>
      );
    }
    return null;
  }, [props.apiData, props.position, props.rideStatus]);

  return (isLoaded && props.position) ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.showInfoPanel ? centerMapWithPanel : props.position}
      zoom={15}
      clickableIcons={false}
      options={mapOptions}
    >
      {renderMapMarkers()}
    </GoogleMap>
  ) : null;
};

export default Map;
