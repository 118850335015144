import openSocket from 'socket.io-client';

// eslint-disable-next-line camelcase
const socket_url = process.env.REACT_APP_URL_SOCKETIO;
let socketConnection;

export const getSocket = () => {
  if (socketConnection) {
    return socketConnection;
  }
  socketConnection = openSocket(socket_url, {
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionAttempts: 10,
  });
  return socketConnection;
};

export const getTracking = ({
  socket, id, callback,
}) => {
  socket.emit('tracking', { command: 'follow', id }, callback);
};
