export const TYPE_SCOOTER = 0;
export const TYPE_BIKE = 1;
export const TYPE_KICK = 2;

export const RIDE_NOTAVAILABLE = 'notavailable';
export const RIDE_CANCELED = 'canceled';
export const RIDE_FINISHED = 'finished';
export const RIDE_STARTED = 'started';

export const MARKER_USERSIZE = {
  width: 40,
  height: 58,
};
export const MARKER_POSITIONSIZE = {
  width: 29,
  height: 40,
};

