import React from 'react';
import { Marker } from '@react-google-maps/api';

import './SingleMarker.css';

const SingleMarker = (props) => (
  <Marker
    position={props.position}
    icon={{
      url: props.icon,
      scaledSize: { width: props.size?.width, height: props.size?.height },
    }}
  />
);

export default SingleMarker;
