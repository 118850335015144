/* eslint-disable camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation_en from '../translations/en/translation.json';
import translation_fr from '../translations/fr/translation.json';
import translation_es from '../translations/es/translation.json';
import translation_ca from '../translations/ca/translation.json';

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translation_en,
      },
      es: {
        translation: translation_es,
      },
      ca: {
        translation: translation_ca,
      },
      fr: {
        translation: translation_fr,
      },
    },

    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
    detection: { order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'] },
  });
export default i18n;
