import React from 'react';
import { useTranslation } from 'react-i18next';

import './Button.css';

const Button = (props) => {
  const { t } = useTranslation();

  const onClick = () => {
    props.onClick?.();
  };

  return (
    <div
      className="button"
      onClick={onClick}
      role="button"
      aria-hidden="true"
      disabled={props.disabled}
      style={props.styles}
    >
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('shareride.shared.cta')}
      </a>
    </div>
  );
};

export default Button;
